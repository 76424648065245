import { emitEvent } from '@/plugins/EventListener';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';

export const SITE_COOKIE_NAME = "_ffi_vid"
export const ACCESS_TOKEN_COOKIE_NAME = "_ffi_token"

export default class ApiUtils {

  static events = []
  static _partnerKey = null
  static _visitorId = null
  static _accessToken = null
  

  static getFullUrl = (urlStr) => {
    if (urlStr.indexOf("http") == 0) {
      return urlStr;
    }
    return urlStr
  }
  
  static defaultHeaders = (url) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    if (this.visitorId() && !this.authToken()) {
      headers['X-VID'] = this.visitorId()
    }
    if (this._partnerKey) {
      headers['X-CLIENT-ID'] = this._partnerKey
    }
    if (this.authToken()) {
      headers['Authorization'] = "Bearer " + this.authToken()
    }
    if (url && url.startsWith("/chat")) {
      headers['x-api-key'] = process.env.NEXT_PUBLIC_CHAT_API_KEY
    }
    return headers
  }

  static setPartnerKey = (key) => {
    this._partnerKey = key
  }
  
  static checkVisitorId = (vid, visitorContext) => {
    if (typeof window !== 'undefined') {
      ApiUtils.getRequest("/v1/visitors/me?vid=" + encodeURIComponent(vid || this.visitorId() || ''), visitorContext).then(data => {
        if (data.visitor_id) {
          this._visitorId = data.visitor_id
          setCookie(SITE_COOKIE_NAME, data.visitor_id)
          emitEvent("initialized", data)
        }
      })
    }
  }

  static checkAuth = () => {
    const url = this.getFullUrl("/v1/agent/users/me")
    return fetch(url, {
      method: 'GET',
      headers: this.defaultHeaders()
    }).then(response => {
      if (response.ok) {
        this.readAuthHeader(response)
        return response.json()
      } else {
        this.readAuthHeader(response)
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        return error
      }
    })
  }

  static clearToken = () => {
    this._accessToken = null
    this._visitorId = null
    deleteCookie(ACCESS_TOKEN_COOKIE_NAME, { path: "/" })
    deleteCookie(SITE_COOKIE_NAME, { path: "/" })
  }
  
  static visitorId = () => {
    if (this._visitorId) return this._visitorId
    return getCookie(SITE_COOKIE_NAME)
  }
  
  static authToken = () => {
    if (this._accessToken) return this._accessToken
    return getCookie(ACCESS_TOKEN_COOKIE_NAME)
  }
  
  static readAuthHeader = (response) => {
    if (response.headers.get('Authorization')) {
      this._accessToken = response.headers.get('Authorization').split(" ")[1]
      setCookie(ACCESS_TOKEN_COOKIE_NAME, this._accessToken)
    }
  }

  static getRequest = (urlStr, params) => {

    let url = this.getFullUrl(urlStr)
    if (urlStr.indexOf("?") < 0) url += "?"
    if (params) {
      Object.keys(params).forEach(function (key) {
        if (params[key]) {
          url += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        }
      });
    }

    if (process.env.NODE_ENV == "developement") console.log("GET " + url)

    return fetch(url, {
      method: 'GET',
      headers: this.defaultHeaders(url)
    }).then(response => {
      if (response.ok) {
        this.readAuthHeader(response)
        return response.json()
      } else {
        this.readAuthHeader(response)
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        return { error: error }
      }
    })
  }

  static postRequest = (urlStr, data, customHeaders, method) => {
    let url = this.getFullUrl(urlStr)
    var jsonBody = data
    var contentType = 'application/json'

    method = method || 'POST'

    if (process.env.NODE_ENV == "developement") console.log(method.toUpperCase() + " " + url)
    
    let headers = this.defaultHeaders(url)
    if (customHeaders) {
      Object.keys(customHeaders).map(k => {
        headers[k] = encodeURIComponent(customHeaders[k])
      })
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(jsonBody)
    })
      .then(response => {
        if (response.ok) {
          this.readAuthHeader(response)
          return response.json()
        } else {
          this.readAuthHeader(response)
          let error = { http: { status: response.status, url: url, payload: jsonBody, response: response, stacktrace: new Error().stack } }
          this.logError("HTTP (" + response.status + ") - POST ERROR - " + url, error)
          return { error: error }
        }
      })
  }

  static postMultipart = (urlStr, formData) => {
    let url = this.getFullUrl(urlStr)
    
    let headers = this.defaultHeaders(url)
    // headers['Content-Type'] = 'multipart/form-data'
    delete headers['Content-Type']

    if (process.env.NODE_ENV == "developement") console.log('POST ' + url)
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData
    })
      .then(response => {
        if (response.ok) {
          this.readAuthHeader(response)
          return response.json()
        } else {
          this.readAuthHeader(response)
          let error = { http: { status: response.status, url: url, payload: formData } }
          this.logError("HTTP (" + response.status + ") POST MULTIPART ERROR - " + url, error)
          return { error: error }
        }
      })
  }
  
  static logError = async (message, error, response) => {
    if (error && error.http && response) error.http.body = await response.text()
    if (process.env.NODE_ENV == 'development') {
      if (message) console.error(message, error)
      if (!message) console.error(error)
    }
    emitEvent("log_error", { message: message, data: error })
  } 

  static logEvent = (event) => {
    this.events.push(event)
    setTimeout(() => {
      if (ApiUtils.events.length > 0) {
        const array = [...ApiUtils.events]
        ApiUtils.events = []
        ApiUtils.postRequest("/api/visitor/track", { payload: { events: array } })
      }
    }, 5000)
  }

  static download = (fileName, records) => {
    const headers = Object.keys(records[0])
    let rows = ['"' + headers.join('","') + '"']
    records.map(row => {
      let rec = []
      headers.map(h => {
        rec.push(row[h])
      })
      rows.push('"' + rec.join('","') + '"')
    })
    let payload = {
      file_name: fileName,
      content_type: "text/csv",
      data: rows.join("\r\n")
    }
    ApiUtils.postRequest("/api/cache", payload)
    .then(data => {
      window.open("/api/download/" + data.key)
    })
  }

  static logEvent = (en, et, c, data, props) => {
    let payload = Object.assign({}, props || {}, {
      "vid": this.visitorId(),
      "en": en,
      "et": et,
      "c": c,
      "sid": this.visitorId(),
      "es": document.location.href,
      "at": new Date().getTime(),
      "d": data,
    })
    emitEvent("log_event_ext", payload)
  }

}
