'use client'

import { useEffect, useState } from "react";
import useEventListener from "./EventListener";


export default function GA4(props) {

  const [loaded,setLoaded] = useState(false)

  useEventListener("config_loaded", event => {
    load(event.detail.creds?.ga_id)
  })

  useEventListener("log_event_ext", event => {
    if (loaded && props.config?.creds?.ga_id) {
      let data = event.detail
      let key = data.en + "_" + data.et
      logGAEvent(key, data)
    }
  })

  useEffect(() => {
    if (props.config?.creds?.ga_id) {
      load(props.config?.creds?.ga_id);
    } else if (props.gaId) {
      load(props.gaId);
    }
  }, [])

  const load = (dtmId) => {
    dtmId = dtmId || props.config?.creds?.ga_id
    if (!loaded && dtmId) {
      const script = document.createElement('script');
      script.src = "https://www.googletagmanager.com/gtag/js?id=" + dtmId;
      script.async = true;
      script.onload = initGTM(dtmId);
      document.body.appendChild(script);
      setLoaded(true)
    }
  }

  const initGTM = (dtmId) => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', dtmId,{ 'debug_mode':true });
    window.gtag = gtag
  }


  return <></>

}

export function logGAEvent(eventName, params) {
  try {
    gtag('event', eventName, params)
  } catch (e) {
    // ignore
  }
}
