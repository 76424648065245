'use client'

import { useEffect } from "react"
import LogRocket from 'logrocket';
import ApiUtils from "@/utils/ApiUtils";

export default function LogRocketPlugin(props) {

  useEffect(() => {
    if (props.config.creds?.logrocket) {
      LogRocket.init('wkdur1/warrantygenie');
      if (ApiUtils.visitorId()) {
        LogRocket.identify(ApiUtils.visitorId(), {
          name: ApiUtils.visitorId(),
          client_key: props.config.partner_key,
          client: props.config.name
        });
      }
    }
  }, [])

  useEffect(() => {
    if (props.config.creds?.logrocket && ApiUtils.visitorId()) {
      LogRocket.identify(ApiUtils.visitorId(), {
        name: ApiUtils.visitorId(),
        client_key: props.config.partner_key,
        client: props.config.name
      });
    }
  }, [ApiUtils.visitorId()])

}